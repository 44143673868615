import { gql } from '@apollo/client';

// Fragments
export const ORGANIZATION_SETTINGS_FRAGMENT = gql`
  fragment OrganizationSettingsFragment on Organization {
    id
    name
    billingEmail
    stripeLast4
    stripeCardDetail
    image
    banner
    contactFormBackgroundColor
    contactFormButtonColor
    helloLeadFormPath
    helloLeadFormUrl
    showcasePath
    showcaseUrl
    discount
    canUpdate {
      value
    }
    isCcPaymentEnabled
    venues {
      id
      name
      internalName
      planName
      cardApplicationFeeRate
      achApplicationFeeRate
      monthlyRate
      billingInterval
      featureFlags
      venueCollection {
        id
        name
      }
      subscription {
        id
        planDefinitionId
        planName
        tier
        billingInterval
        nextBillingDate
        monthlyRate
        cardApplicationFeeRate
        achApplicationFeeRate
        stripeSourceId
        endDate
        status
        upcomingSubscription {
          planName
          tier
          monthlyRate
          billingInterval
          cardApplicationFeeRate
          achApplicationFeeRate
          stripeSourceId
        }
      }
    }
    organizationMemberships {
      id
      role
      user {
        id
        firstName
        lastName
        fullName
        email
        image
        activeVenueMemberships {
          id
          venue {
            id
            name
          }
        }
      }
    }
  }
`;

// Queries
export const organizationQuery = gql`
  query CoreOrganization($id: ID!) {
    organization(id: $id) {
      ...OrganizationSettingsFragment
    }
  }
  ${ORGANIZATION_SETTINGS_FRAGMENT}
`;

// Mutations
export const updateOrganizationMutation = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        ...OrganizationSettingsFragment
      }
      errors {
        message
      }
    }
  }
  ${ORGANIZATION_SETTINGS_FRAGMENT}
`;

export const createStripePaymentMethodTokenFromPlaidPayloadMutation = gql`
  mutation CreateStripePaymentMethodTokenFromPlaidPayload(
    $input: CreateStripePaymentMethodTokenFromPlaidPayloadInput!
  ) {
    createStripePaymentMethodTokenFromPlaidPayload(input: $input) {
      stripeToken
      errors {
        message
      }
    }
  }
`;

export const removeOrganizationPaymentMethod = gql`
  mutation RemoveOrganizationPaymentMethod(
    $input: RemoveOrganizationPaymentMethodInput!
  ) {
    removeOrganizationPaymentMethod(input: $input) {
      organization {
        ...OrganizationSettingsFragment
      }
      errors {
        message
      }
    }
  }
  ${ORGANIZATION_SETTINGS_FRAGMENT}
`;

export const updateVenueSubscriptionPaymentMethod = gql`
  mutation UpdateVenueSubscriptionPaymentMethod(
    $input: UpdateVenueSubscriptionPaymentMethodInput!
  ) {
    updateVenueSubscriptionPaymentMethod(input: $input) {
      organization {
        ...OrganizationSettingsFragment
      }
      errors {
        message
      }
    }
  }
  ${ORGANIZATION_SETTINGS_FRAGMENT}
`;
